/* eslint-env es6 */

import pick from 'lodash/pick';
import intersectionBy from 'lodash/intersectionBy';

const VALID_SIZES = [
  [300, 50],
  [300, 250],
  [300, 600],
  [320, 100],
  [320, 480],
  [320, 50],
  [336, 280],
  [728, 90],
  [970, 250],
  [640, 360],
];

const get_a9_sizes = ad_placement => {
  return intersectionBy(
    ad_placement.sizes,
    VALID_SIZES,
    size => size.join('x')
  );
};

export default class AmazonBackend {
  constructor({config, targeting, id_generator}) {
    this.config = config;
    this.id_generator = id_generator;

    window.apstag.init({
      pubID: config.a9_pub_id,
      params: {
        sections: [
          targeting.ad_page_type,
          targeting.primary_tag,
        ].filter(Boolean).join('.'),
      },
    });
  }

  get_targeting_for_ad_units(ad_units) {
    const slots = ad_units.
      filter(unit => this.config.ad_placements[unit.name].a9).
      map(({name, instance}) => {
        const placement = this.config.ad_placements[name];

        return {
          slotID: this.id_generator.id_for(name, instance),
          slotName: name,
          sizes: get_a9_sizes(placement),
          mediaType: placement.a9.media_type || 'display',
        };
      });

    if (!slots.length) {
      return Promise.resolve([]);
    }

    const video_slot_ids = [];
    for (const slot of slots) {
      if (slot.mediaType === 'video') {
        video_slot_ids.push(slot.slotID);
        slot.slotID = 'preroll';
      }
    }

    return new Promise((resolve) => {
      window.apstag.fetchBids(
        {
          slots,
          timeout: this.config.prebid_timeout_ms,
        },
        results => {
          resolve(results.map(bid => {
            let id = bid.slotID;

            if (id === 'preroll') {
              id = video_slot_ids.shift();
            }

            const targeting_keys = window.apstag.targetingKeys(bid.mediaType || 'display');

            return {id, targeting: pick(bid, targeting_keys)};
          }));
        }
      );
    });
  }

  remove_ad_units() { }
  trigger_user_syncs() { }
}
