/* eslint-env es6 */

import defaults from 'lodash/defaults';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import adblockDetect from 'adblock-detect';

import DFP from './dfp';
import Preflight from './preflight';
import IasBackend from './preflight/ias_backend';
import AmazonBackend from './preflight/amazon_backend';
import AnaBackend from './preflight/ana_backend';
import page_targeting from './page_targeting';
import pick_experiment from './pick_experiment';
import IdGenerator from './id_generator';
import {count} from '../modules/librato';
import PromiseStore from './promise_store';
import {GDPRManager} from './gdpr_manager';

const AD_LOAD_TIMEOUT = 10000;

export default function Ads(queue = []) {
  const ads = new Ads.Core(queue);
  this.push = fn => fn(ads);
}

Ads.Core = class Core {
  constructor(queue = []) {
    queue.forEach(fn => fn(this));
  }

  initialize({config, targeting, initial_units, initial_last_reset_at}) {
    this.render_promise_store = new PromiseStore();

    const experiment = pick_experiment(config);
    this.config = experiment.update_config(config);

    this.id_generator = new IdGenerator(initial_last_reset_at);

    this.targeting = defaults(
      targeting,
      {random24: Math.ceil(Math.random() * 24)},
      {from_google: document.referrer.includes('google.com').toString()},
      experiment.targeting,
      page_targeting(),
    );

    const backends = [
      IasBackend,
      AnaBackend,
      AmazonBackend,
    ];

    if (this.targeting.campaign_id) {
      this.config.disabled_ad_units = [];
    }

    this.disabled_ad_units = new Set(this.config.disabled_ad_units);
    this.gdpr_manager = new GDPRManager({cmp_enabled: config.cmp_enabled});

    this.preflight = new Preflight({
      config,
      gdpr_manager: this.gdpr_manager,
      targeting: this.targeting,
      id_generator: this.id_generator,
      backends: backends,
    });

    this.dfp = new DFP({
      config,
      gdpr_manager: this.gdpr_manager,
      targeting: this.targeting,
      id_generator: this.id_generator,
    });

    this.instrument_ad_blocking();
    this.ready = this.define_initial_slots(initial_units.map(name => [name, name]));
  }

  async define_initial_slots(initial_instances) {
    await this.gdpr_manager.wait_for_consent();
    initial_instances = initial_instances.filter(([name]) => !this.disabled_ad_units.has(name));
    const bulk_targeting = await this.preflight.ensure_targeting_for_placements(initial_instances);

    const instances_by_id = keyBy(initial_instances,
      ([name, instance]) => this.id_generator.id_for(name, instance));
    const placements_with_targeting = bulk_targeting.map(({id, targeting}) => {
      const [name, instance] = instances_by_id[id];
      return {id, targeting, name, instance};
    });

    const master_first_sorting = ({name}) =>
      this.config.ad_placements[name].master_on_page ? 1 : 2;

    const slots = sortBy(placements_with_targeting, master_first_sorting).
      map(({name, instance, targeting}) => {
        return this.dfp.define_or_update_slot(name, instance, targeting);
      });

    return Promise.all(slots);
  }

  reset_for_new_page_view({targeting}) {
    this.id_generator.reset_for_new_page_view();
    this.preflight.reset_for_new_page_view();
    this.dfp.reset_for_new_page_view({targeting});
  }

  id_for(name, instance) {
    return this.id_generator.id_for(name, instance);
  }

  async render(name, instance) {
    await this.ready;
    let timeoutID;

    if (this.disabled_ad_units.has(name)) {
      return {isEmpty: true, impressionViewable: false, disabled: true};
    }

    const {breakpoints} = this.config.ad_placements[name];

    if (breakpoints) {
      const screenWidth = window.innerWidth;
      const currentBreakPoint = breakpoints.findLast(({maxScreenWidth}) => (
        screenWidth < maxScreenWidth
      ));

      if (currentBreakPoint) {
        this.config.ad_placements[name].sizes = this.config.ad_placements[name].sizes.filter(([width]) => width <= currentBreakPoint.maxAdWidth);
        this.dfp.update_ad_placements(this.config.ad_placements);
      }
    }

    const adTimeout = new Promise((resolve) => {
      timeoutID = setTimeout(() => resolve({isEmpty: true, timeout: true, impressionViewable: false}), AD_LOAD_TIMEOUT);
    });

    return this.render_promise_store.conditionally_set(`${name}-${instance}`, async () => {
      const [{targeting: preflight_targeting}] = await this.ensure_targeting_for_placements([[name, instance]]);

      const slot = await this.dfp.define_or_update_slot(
        name,
        instance,
        Object.assign({}, preflight_targeting)
      );

      return Promise.race([this.dfp.render_slot(slot), adTimeout]).then(result => {
        clearTimeout(timeoutID);
        if (result.timeout) {
          count('render.timeout', {source: `ads.${name}`});
        }
        return result;
      });
    });
  }

  async refresh(name, instance, height_limit) {
    await this.ready;

    if (this.disabled_ad_units.has(name)) {
      return;
    }

    if (height_limit) {
      this.config.ad_placements[name].sizes = this.config.ad_placements[name].sizes.filter(([_width, height]) => (
        height <= height_limit
      ));
      this.dfp.update_ad_placements(this.config.ad_placements);
    }

    this.preflight.reset_for_ad_refresh(name, instance);
    const [{targeting: preflight_targeting}] = await this.ensure_targeting_for_placements([[name, instance]]);

    return this.dfp.refresh_slot(name, instance, preflight_targeting, Boolean(height_limit));
  }

  async dispose(name, instance) {
    await this.ready;

    return this.preflight.dispose_targeting_for_placement(name, instance);
  }

  async ensure_targeting_for_placements(placements) {
    await this.ready;

    return this.preflight.ensure_targeting_for_placements(
      placements.filter(([name]) => !this.disabled_ad_units.has(name))
    );
  }

  get_base_targeting(name) {
    return {
      ...this.targeting,
      ...this.config.ad_placements[name].kv,
    };
  }

  async get_async_targeting(name, instance) {
    await this.ready;

    if (this.disabled_ad_units.has(name)) {
      return {};
    }

    const [{targeting}] = await this.ensure_targeting_for_placements([[name, instance]]);

    return targeting;
  }

  instrument_ad_blocking() {
    setTimeout(() => {
      adblockDetect((adblockDetected) => {
        if (adblockDetected) count('adblock.enabled', {source: 'ads'});
        else count('adblock.disabled', {source: 'ads'});
      });
    });
  }

  trigger_user_syncs() {
    this.preflight.trigger_user_syncs();
  }
};
