import debounce from 'lodash/debounce';
import mergeWith from 'lodash/mergeWith';

const METRICS_URL = 'https://librato-collector.genius.com/v1/metrics';

const queue = [];
const isClient = typeof window !== 'undefined';

const flush_queue = debounce(() => {
  const body = mergeWith(
    ...queue.splice(0),
    (operations1, operations2) => (operations1 || []).concat(operations2)
  );
  if (navigator.sendBeacon) {
    navigator.sendBeacon(
      METRICS_URL,
      new Blob([JSON.stringify(body)], {type: 'application/json'})
    );
  } else {
    fetch(
      METRICS_URL,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {'Content-Type': 'application/json'},
      }
    );
  }
}, 2000, {leading: false, trailing: true});

isClient && document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'hidden') {
    flush_queue.flush();
  }
});

export function measure(name, value, {source}) {
  queue.push({gauges: [{name, value, source}]});
  flush_queue();
}

export function count(name, {source}) {
  queue.push({counters: [{name, value: 1, source}]});
  flush_queue();
}
