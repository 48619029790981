/* eslint-env es6 */

const MAX_CONSENT_TIMEOUT = 5000;
const TCF_VERSION = 2;

export class GDPRManager {
  constructor({cmp_enabled}) {
    this.cmp_enabled = cmp_enabled;
    this.consent_promise = this.get_consent_promise();
    this.tc_string = '';
  }

  get_consent_promise() {
    if (!this.cmp_enabled) return Promise.resolve();

    return new Promise((resolve) => {
      window.__tcfapi('addEventListener', TCF_VERSION, (tcData, success) => this.handle_tcf_event({tcData, success, resolve}));

      setTimeout(() => resolve(), MAX_CONSENT_TIMEOUT);
    });
  }

  handle_tcf_event = ({tcData, success, resolve}) => {
    const {gdprApplies} = tcData;

    if (!success || !gdprApplies) return resolve();

    const {eventStatus, tcString} = tcData;
    this.tc_string = tcString;

    if (eventStatus === 'tcloaded' || eventStatus === 'useractioncomplete') {
      resolve();
    }
  }

  wait_for_consent() {
    return this.consent_promise;
  }

  get_tc_string() {
    return this.tc_string;
  }
}
