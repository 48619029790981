import find from 'lodash/find';
import forEach from 'lodash/forEach';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import map from 'lodash/map';
import pull from 'lodash/pull';
import remove from 'lodash/remove';
import uniq from 'lodash/uniq';
import without from 'lodash/without';

class None {
  update_config(config) {
    return config;
  }

  get targeting() {
    return {};
  }
}

class Base {
  update_config(config) {
    return config;
  }

  get targeting() {
    return {gexperiment: this.experiment_name};
  }
}

class Control extends Base {
  constructor() {
    super();
    this.experiment_name = 'control';
  }
}

class SuppressHeaderBidder extends Base {
  static generate(config) {
    return map(without(uniq(flatMap(
      Object.values(config.ad_placements),
      placement => map(get(placement, 'prebid_placements', []), 'bidder')
    )), ...config.prebid.experimental_bidders), bidder => new this(bidder));
  }

  constructor(bidder) {
    super();
    this.suppressed_bidder = bidder;
    this.experiment_name = `suppress-${bidder}-header`;
  }

  update_config(config) {
    forEach(config.ad_placements, (placement) => {
      remove(placement.prebid_placements, {bidder: this.suppressed_bidder});
    });
    return config;
  }
}

class ExperimentalHeaderBidder extends Base {
  static generate(config) {
    return config.prebid.experimental_bidders.map(bidder => new this(bidder));
  }

  constructor(bidder) {
    super();
    this.included_bidder = bidder;
    this.experiment_name = `experimental-${bidder}-header`;
  }

  update_config(config) {
    pull(config.prebid.experimental_bidders, this.included_bidder);
    return config;
  }
}

class PrebidServer extends Base {
  static generate() {
    return [
      new this('appnexus'),
      new this('rubicon'),
    ];
  }

  constructor(host) {
    super();
    this.host = host;
    this.experiment_name = `prebid-server-${host}`;
  }

  update_config(config) {
    config.prebid_server_enabled = true;
    const server_config = config.prebid_server[this.host];
    config.prebid_server_account_id = server_config.account_id;
    config.prebid_server_endpoint = server_config.endpoint;
    config.prebid_server_sync_endpoint = server_config.sync_endpoint;
    return config;
  }
}

class PrebidTimeout extends Base {
  static generate(config) {
    return [
      new this(config.prebid_timeout_ms / 2),
      new this(config.prebid_timeout_ms * 2),
    ];
  }

  constructor(timeout) {
    super();
    this.timeout = timeout;
    this.experiment_name = `prebid-timeout-${timeout}`;
  }

  update_config(config) {
    config.prebid_timeout_ms = this.timeout;
    return config;
  }
}

class NoHeader extends Base {
  constructor() {
    super();
    this.suppresses_a9 = true;
    this.experiment_name = 'no-header';
  }

  update_config(config) {
    forEach(config.ad_placements, (placement) => {
      placement.a9 = false;
      placement.prebid_placements = [];
      placement.exclude_assembly = true;
    });
    return config;
  }
}

class SuppressA9 extends Base {
  constructor() {
    super();
    this.suppresses_a9 = true;
    this.experiment_name = 'suppress-a9';
  }

  update_config(config) {
    forEach(config.ad_placements, (placement) => {
      placement.a9 = false;
    });
    return config;
  }
}

class SuppressAna extends Base {
  constructor() {
    super();
    this.experiment_name = 'suppress-ana';
  }

  update_config(config) {
    forEach(config.ad_placements, (placement) => {
      placement.exclude_assembly = true;
    });
    return config;
  }
}

class TargetingOnly extends Base {
  static generate() {
    return [
      new this('no-dynamic-allocation'),
      new this('no-ebda'),
      new this('no-high-impact-inread'),
      new this('suppress-adx'),
      new this('suppress-indexExchange-ebda'),
      new this('suppress-openx-ebda'),
      new this('suppress-saambaa'),
      new this('suppress-teads'),
      new this('suppress-tonefuse'),
      new this('suppress-triplelift-ebda'),
      new this('suppress-undertone'),
      new this('suppress-zedo'),
    ];
  }

  constructor(experiment_name) {
    super();
    this.experiment_name = experiment_name;
  }
}

const get_forced_experiment = (all_experiments) => {
  const experiment_name =
    get(/[\?&]force_ad_experiment=([^&]+)/.exec(window.location.search), 1);
  if (experiment_name) return find(all_experiments, {experiment_name});
};

export default function pick_experiment(config) {
  const all_experiments = [
    new Control(),
    new NoHeader(),
    new SuppressA9(),
    new SuppressAna(),
    ...PrebidServer.generate(config),
    ...SuppressHeaderBidder.generate(config),
    ...ExperimentalHeaderBidder.generate(config),
    ...TargetingOnly.generate(config),
    ...PrebidTimeout.generate(config),
  ];

  const forced_experiment = get_forced_experiment(all_experiments);
  if (forced_experiment) return forced_experiment;

  const seed = Math.floor(Math.random() * 250);
  return get(all_experiments, seed, new None());
}
