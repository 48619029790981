/* eslint-env es6 */

import maxBy from 'lodash/maxBy';
import omit from 'lodash/omit';
import mapValues from 'lodash/mapValues';
import castArray from 'lodash/castArray';
import {count, measure} from '../../modules/librato';
import LocalStorage from '../local_storage';

const IMPRESSION_PIXEL_URL_ORIGINS = [
  new RegExp('^https://tpc.googlesyndication.com$'),
  new RegExp('^https://\\w+.safeframe.googlesyndication.com$'),
  new RegExp('^https?://ana-sdk-creative.s3-website-us-west-2.amazonaws.com$'),
];
const authorizedImpressionEvent = (origin) =>
  IMPRESSION_PIXEL_URL_ORIGINS.some(o => o.test(origin));

const HOURS_IN_MS = 60 * 60 * 1000;

const VIDEO_POSITIONS = {
  above_the_fold: 1,
  below_the_fold: 3,
};

const BIDDER_REFRESH_RATES = {
  'trade_desk': 30,
};

const REQUIREMENT_SETS = {
  none: 0,
  gdpr: 1,
  ccpa: 2,
};

export default class AnaBackend {
  constructor({config, gdpr_manager, targeting, id_generator}) {
    this.config = config;
    this.targeting = targeting;
    this.id_generator = id_generator;
    this.bids = new Map();
    this.gdpr_manager = gdpr_manager;
    this.requirement_set = this.config.cmp_enabled ? REQUIREMENT_SETS.gdpr : REQUIREMENT_SETS.none;
    this.uid = window.getAnaUid();

    window.addEventListener('message', this.sync_url_message_handler.bind(this));
    window.addEventListener('message', this.assembly_creative_message_handler.bind(this));
  }

  get_targeting_for_ad_units(ad_units) {
    return Promise.allSettled(
      ad_units.map(this.get_targeting_for_unit.bind(this))
    ).then(results =>
      results.filter(({status}) => status === 'fulfilled').map(({value}) => value)
    );
  }

  async get_targeting_for_unit({name, instance}) {
    const id = this.id_generator.id_for(name, instance);
    const placement = this.config.ad_placements[name];

    if (placement.exclude_assembly) {
      return {id, targeting: {}};
    }
    this.clear_old_bids();

    const tcString = this.gdpr_manager.get_tc_string();
    const bidResponse = await this._ana_api_fetch('/wana/bids/request', {
      params: {
        uid: this.uid,
      },
      body: {
        requirement_set: this.requirement_set,
        ...this.basic_bid_request(name),
        ...(placement.assembly_video && this.video_bid_request(placement)),
        ...(tcString ? {
          consent: {tcfV2: tcString},
        } : {}),
      },
    });

    const highestBid = maxBy(bidResponse.bids, 'value');
    this.bids.set(highestBid.bid_id, highestBid);

    return {
      id,
      targeting: highestBid.targeting,
    };
  }

  remove_ad_units() { }

  clear_old_bids() {
    this.bids = new Map(
      Array.from(this.bids).filter(
        ([_key, {expires: expirationTime}]) => expirationTime > Date.now()
      )
    );
    measure('ana_backend.bids_cache_size', this.bids.size, {source: `${this.config.platform}_${this.targeting.ad_page_type}`});
  }

  basic_bid_request(name) {
    return {
      ad_unit: `/${this.config.dfp_network_id}/${name}`,
      auction_timeout: this.config.prebid_timeout_ms,
      inventory_type: 'web',
      additional_targeting: mapValues(this.targeting, v => castArray(v).join(',')),
      opportunity_data: {
        platform: 'web',
      },
      site: {
        page: this.rewrite_host(document.location).href,
        referer: document.referrer,
      },
      matched_ids: {
        ids: this.synced_bidder_ids(),
      },
    };
  }

  video_bid_request({sizes: [[width, height]], assembly_video}) {
    return {
      video_width: width,
      video_height: height,
      video_start_delay: /* PreRoll */ 0,
      video_placement: /* InArticle */ 3,
      video_position: VIDEO_POSITIONS[assembly_video.position],
      video_playback_method: /* EnterViewpointSoundOff */ 6,
      video_delivery: /* Progressive*/ 2,
    };
  }

  rewrite_host(location) {
    return Object.assign(new URL(location), {
      hostname: this.config.ana_host_rewrite_map[location.hostname],
    });
  }

  async trigger_user_syncs() {
    let existing_ids = Object.keys(this.synced_bidder_ids());
    const last_synced_ago_ms = Date.now() - this.last_synced_at_ms();

    if (last_synced_ago_ms > 24 * HOURS_IN_MS) {
      existing_ids = [];
      this.track_synced_at();
    }

    const tcString = this.gdpr_manager.get_tc_string();
    const {
      sync_urls,
      cookie_ids = {},
    } = await this._ana_api_fetch('/doh/sync', {
      params: {
        uid: this.uid,
        property_id: 'genius.com',
      },
      body: {
        synced_ids: existing_ids,
        requirement_set: this.requirement_set,
        ...(tcString ? {consent: tcString} : {}),
      },
    });

    for (const [bidder_id, partner_uid] of Object.entries(cookie_ids)) {
      this.set_bidder_id(bidder_id, partner_uid);
    }

    Object.values(sync_urls).forEach(this.inject_sync_url_iframe.bind(this));
  }

  inject_sync_url_iframe(url) {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', url);
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('scrolling', 'no');
    iframe.setAttribute('marginheight', '0');
    iframe.setAttribute('marginwidth', '0');
    iframe.setAttribute('TOPMARGIN', '0');
    iframe.setAttribute('LEFTMARGIN', '0');
    iframe.setAttribute('allowtransparency', 'true');
    iframe.setAttribute('width', '0');
    iframe.setAttribute('height', '0');
    iframe.setAttribute('data-property-id', 'genius.com');
    document.body.appendChild(iframe);
  }

  sync_url_message_handler(event) {
    if (
      event.origin === this.config.ana_base_url &&
      event.data.msgType === 'cookieSyncingSuccess'
    ) {
      this.set_bidder_id(event.data.bidderId, event.data.partnerUid);
    }
  }

  set_bidder_id(bidder_id, partner_uid) {
    LocalStorage.set_item('ana_synced_bidder_ids', {
      ...this.synced_bidder_ids(),
      [bidder_id]: partner_uid,
    });
  }

  synced_bidder_ids() {
    return LocalStorage.get_item('ana_synced_bidder_ids', {}) || {};
  }

  last_synced_at_ms() {
    return LocalStorage.get_item('ana_last_synced_at_ms') || 0;
  }

  track_synced_at() {
    return LocalStorage.set_item('ana_last_synced_at_ms', Date.now());
  }

  assembly_creative_message_handler({data, origin, source}) {
    if (authorizedImpressionEvent(origin) && data?.bidID) {
      const {bidID, adUnitID} = data;

      if (this.bids.has(bidID)) {
        const bid = this.bids.get(bidID);

        if (source) {
          source.postMessage(JSON.stringify(bid), origin);
          this.add_refresh_rate_override(source, bid);
        }

        this.invalidate_bid(bid);
        this.render_impression_pixels(bid);
        this.bids.delete(bidID);
        count('ana_backend.successful_bid', {source: adUnitID});
      } else {
        count('ana_backend.uncached_bid', {source: adUnitID});
      }
    }
  }

  invalidate_bid(bid) {
    this._ana_api_fetch('/wana/bids/invalidate', {
      params: {
        uid: this.uid,
        ad_unit: bid.ad_unit_id,
      },
      body: {
        bids: [
          omit(bid, ['creative', 'impression_pixels', 'view_pixels', 'click_pixels']),
        ],
      },
    });
  }

  render_impression_pixels({bid_id, impression_pixels}) {
    for (const url of impression_pixels) {
      const pixel = document.createElement('img');
      pixel.setAttribute('data-bid-id', bid_id);
      pixel.setAttribute('src', url);
      pixel.setAttribute('width', 1);
      pixel.setAttribute('height', 1);
      document.body.appendChild(pixel);
    }
  }

  add_refresh_rate_override(source, bid) {
    if (BIDDER_REFRESH_RATES[bid.bidder_name]) {
      const iframe = Array.from(document.querySelectorAll('iframe')).find(i => i.contentWindow === source);

      if (iframe) {
        iframe.dataset.geniusAnaRefreshRateOverride = BIDDER_REFRESH_RATES[bid.bidder_name];
      }
    }
  }

  async _ana_api_fetch(path, {
    params,
    body,
    headers,
    method = 'POST',
    ...options
  }) {
    const metrics_source = path.replaceAll('/', '_');
    count('ana_backend.api_request', {source: metrics_source});

    const url = new URL(path, this.config.ana_base_url);
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.set(key, value);
    }

    try {
      const response = await fetch(url, {
        credentials: 'include',
        ...options,
        ...(body && {body: JSON.stringify(body)}),
        method,
        headers: {
          'Content-Type': 'application/json',
          'ana-api-key': this.config.ana_api_key,
          lib_version: this.config.masquarade_as_ana_sdk_version,
          publisher_version: 'web_0.0.1',
          ...headers,
        },
      });

      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      } else {
        return await response.json();
      }
    } catch (error) {
      count('ana_backend.api_request_error', {source: metrics_source});
      throw error;
    }
  }
}
