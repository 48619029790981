/* eslint-env es6 */

import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import values from 'lodash/values';
import PromiseStore from '../promise_store';

export default class Preflight {
  constructor({config, gdpr_manager, targeting, id_generator, backends}) {
    this.config = config;
    this.id_generator = id_generator;
    this.ads_store = new PromiseStore();
    this.requested_targeting = new Set();
    this.backends = backends.map(Backend => new Backend({
      config,
      gdpr_manager,
      targeting,
      id_generator,
    }));
  }

  reset_for_new_page_view() {
    const ids = this.ads_store.keys();
    this.ads_store.clear();
    for (const backend of this.backends) {
      backend.remove_ad_units(ids);
    }
  }

  reset_for_ad_refresh(name, instance) {
    const id = this.id_generator.id_for(name, instance);

    this.requested_targeting.delete(id);
    this.ads_store.delete(id);
  }

  ensure_targeting_for_placements(placements) {
    const ad_units_to_auction = [];
    const ad_unit_ids = placements.map(([name, instance]) =>
      this.id_generator.id_for(name, instance));

    placements.forEach(([name, instance]) => {
      const id = this.id_generator.id_for(name, instance);
      if (!this.requested_targeting.has(id)) {
        this.requested_targeting.add(id);
        ad_units_to_auction.push({name, instance});
      }
    });

    this._get_targeting_for_ad_units(ad_units_to_auction).
      then(this._store_results.bind(this)).
      then(() => ad_unit_ids.forEach(id =>
        this.ads_store.conditionally_set(id, () => ({
          id,
          targeting: {},
        }))));

    return this.ads_store.multi_get(ad_unit_ids);
  }

  dispose_targeting_for_placement(name, instance) {
    const id = this.id_generator.id_for(name, instance);

    this.ads_store.delete(id);
    return Promise.all(
      this.backends.map(backend => backend.remove_ad_units([id]))
    );
  }

  trigger_user_syncs() {
    this.backends.forEach(backend => backend.trigger_user_syncs());
  }

  _store_results(results) {
    for (const result of results) {
      this.ads_store.set(result.id, result);
    }
  }

  _get_targeting_for_ad_units(ad_units) {
    return Promise.all(
      this.backends.map(backend => backend.get_targeting_for_ad_units(ad_units).catch(() => []))
    ).then((responses) => {
      return values(
        mergeWith(
          {},
          ...responses.map(collection => keyBy(collection, 'id')),
          (aggregate, input) => {
            if (isNil(aggregate) || isNil(input)) {
              return;
            }

            return {
              id: aggregate.id,
              targeting: merge({}, aggregate.targeting, input.targeting),
            };
          }
        )
      );
    });
  }
}
