/* eslint-env es6 */

function is_available() {
  try {
    const x = '__storage_test__';
    window.localStorage.setItem(x, x);
    window.localStorage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

function _unsafe_set_item(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

function _unsafe_get_item(key) {
  return JSON.parse(window.localStorage.getItem(key) || 'null');
}

const _unsafe_remove_item = window.localStorage.removeItem.bind(window.localStorage);

export default {
  set_item(key, value) {
    if (is_available()) { _unsafe_set_item(key, value); }
  },

  get_item(key, default_value) {
    return is_available() ? _unsafe_get_item(key) : default_value;
  },

  remove_item(key) {
    if (is_available()) { _unsafe_remove_item(key); }
  },
};
