/* eslint-env es6 */

import includes from 'lodash/includes';
import PromiseStore from './promise_store';

window.googletag = window.googletag || {};
window.googletag.cmd = window.googletag.cmd || [];

const AD_NAME_REGEX = /\/([^\/]+)\/?$/;
const SIZE_MAPPING_UNIVERSAL_BREAKPOINT = [0, 0];

const googletag_loaded = new Promise((resolve) =>
  window.googletag.cmd.push(() => resolve(window.googletag)));

const set_targeting = (object, ...targeting) => {
  targeting.forEach((kv) => {
    Object.keys(kv || {}).forEach((key) => {
      if (!kv[key] && kv !== false) return;
      object.setTargeting(key, kv[key].toString());
    });
  });
};

export default class DFP {
  constructor({config, gdpr_manager, id_generator, targeting}) {
    this.ad_placements = config.ad_placements;
    this.network_id = config.dfp_network_id;
    this.consent_timeout = config.consent_timeout;
    this.cmp_enabled = config.cmp_enabled;
    this.gdpr_manager = gdpr_manager;
    this.id_generator = id_generator;
    this.slot_store = new PromiseStore();
    this.refresh_counters = new Map();
    this.slot_rendered_callbacks = new Map();
    this.slot_impression_viewable_callbacks = new Map();
    this.ready = Promise.all([
      googletag_loaded,
      this.gdpr_manager.wait_for_consent(),
    ]).then(([googletag]) => {
      googletag.pubads().enableSingleRequest();
      googletag.pubads().collapseEmptyDivs(true);
      googletag.pubads().addEventListener(
        'slotRenderEnded',
        this._on_slot_render_ended.bind(this)
      );
      googletag.pubads().addEventListener(
        'impressionViewable',
        this._on_impression_viewable.bind(this)
      );
      set_targeting(googletag.pubads(), targeting);
      const uid = window.getAnaUid();
      googletag.pubads().setPublisherProvidedId(uid);

      googletag.enableServices();
      return googletag;
    });
  }

  reset_for_new_page_view({targeting}) {
    this.slot_store.clear();
    this.ready = this.ready.then((googletag) => {
      googletag.destroySlots();
      googletag.pubads().clear();
      googletag.pubads().clearTargeting();
      googletag.pubads().updateCorrelator();
      set_targeting(googletag.pubads(), targeting);

      return googletag;
    });
  }

  refresh_slots(targeting, slotNames) {
    this.ready = this.ready.then(googletag => {
      const refreshSlots = googletag.pubads().getSlots().filter(slot => {
        const slotName = slot.getAdUnitPath().match(AD_NAME_REGEX)[1];
        if (this.ad_placements[slotName].refresh_unsafe || !includes(slotNames, slotName)) {
          return false;
        }

        slot.clearTargeting();
        set_targeting(slot, this.ad_placements[slotName].kv, targeting);
        return true;
      });

      this.update_refresh_count(refreshSlots);
      googletag.pubads().refresh(refreshSlots);
      return googletag;
    });

    return this.ready;
  }

  render_slot(slot) {
    return new Promise((resolve) => {
      this.slot_rendered_callbacks.set(slot.getSlotElementId(), resolve);
      this._fetch_ad_for_slot(slot);
    });
  }

  define_or_update_slot(name, instance, targeting) {
    if (!this.ad_placements[name]) {
      return Promise.reject(new Error(`No ad with name '${name}'`));
    }

    const id = this.id_generator.id_for(name, instance);

    return this.slot_store.conditionally_set(id, () => {
      const path = `/${this.network_id}/${name}`;
      const placement = this.ad_placements[name];
      if (placement.out_of_page) {
        const format_or_id = placement.out_of_page_format || id;
        return this._define_out_of_page_slot(path, format_or_id);
      } else {
        const sizes = placement.sizes;

        return this._define_slot(path, sizes, id);
      }
    }).then((slot) => {
      set_targeting(slot, this.ad_placements[name].kv, targeting);
      return slot;
    });
  }

  async refresh_slot(name, instance, targeting, update_slot_sizes) {
    const googletag = await this.ready;

    const id = this.id_generator.id_for(name, instance);
    const slot = await this.slot_store.get(id);

    if (update_slot_sizes) {
      const mapping = googletag.sizeMapping().addSize(SIZE_MAPPING_UNIVERSAL_BREAKPOINT, this.ad_placements[name].sizes).build();
      slot.defineSizeMapping(mapping);
    }

    slot.clearTargeting();
    set_targeting(slot, this.ad_placements[name].kv, targeting);

    const refreshed_slot = new Promise((resolve) => {
      this.slot_rendered_callbacks.set(slot.getSlotElementId(), resolve);
      this._refresh_ad_for_slot(slot, {changeCorrelator: false});
    });

    return refreshed_slot;
  }

  update_refresh_count(slots) {
    slots.forEach((slot) => {
      const slot_targeting = slot.getTargetingKeys();
      if (!slot_targeting.find(key => key === 'mlrf')) {
        const ad_unit = slot.getAdUnitPath();
        let refresh_counter = this.refresh_counters.get(ad_unit);

        if (refresh_counter === undefined) {
          refresh_counter = 0;
        } else {
          refresh_counter++;
        }

        this.refresh_counters.set(ad_unit, refresh_counter);
        slot.setTargeting('mlrf', this.refresh_counters.get(ad_unit).toString());
      }
    });
  }

  update_ad_placements(ad_placements) {
    this.ad_placements = ad_placements;
  }

  _on_slot_render_ended(event) {
    const id = event.slot.getSlotElementId();
    const callback = this.slot_rendered_callbacks.get(id);

    if (callback) {
      const impressionViewable = new Promise((resolve) => {
        this.slot_impression_viewable_callbacks.set(id, resolve);
      });

      callback({...event, impressionViewable});
    }
    this.slot_rendered_callbacks.delete(id);
  }

  _on_impression_viewable(event) {
    const id = event.slot.getSlotElementId();

    const callback = this.slot_impression_viewable_callbacks.get(id);
    if (callback) {
      callback(event);
    }
  }

  _define_slot(path, sizes, id) {
    return this.ready.then((googletag) => {
      const slot = googletag.defineSlot(path, sizes, id);
      const mapping = googletag.sizeMapping().addSize(SIZE_MAPPING_UNIVERSAL_BREAKPOINT, sizes).build();
      slot.defineSizeMapping(mapping);
      slot.addService(googletag.pubads());
      return slot;
    });
  }

  _define_out_of_page_slot(path, format_or_id) {
    return this.ready.then((googletag) => {
      const {OutOfPageFormat} = googletag.enums;
      const out_of_page_format_or_id = OutOfPageFormat.hasOwnProperty(format_or_id) ?
        OutOfPageFormat[format_or_id] : format_or_id;
      const slot = googletag.defineOutOfPageSlot(path, out_of_page_format_or_id);
      slot.addService(googletag.pubads());
      return slot;
    });
  }

  _fetch_ad_for_slot(slot) {
    return this.ready.then(googletag => {
      this.update_refresh_count([slot]);
      return googletag.display(slot.getSlotElementId());
    });
  }

  _refresh_ad_for_slot(slot, opts = {}) {
    return this.ready.then(googletag => {
      this.update_refresh_count([slot]);
      return googletag.pubads().refresh([slot], opts);
    });
  }
}
