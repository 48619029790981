/* eslint-env es6 */

import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import {count} from '../../modules/librato';

export default class IasBackend {
  constructor({config, id_generator}) {
    this._config = config;
    this._id_generator = id_generator;

    window.__iasPET = window.__iasPET || {};
    window.__iasPET.queue = window.__iasPET.queue || [];
    window.__iasPET.pubId = config.ias_pubid;
  }

  get_targeting_for_ad_units(ad_units) {
    if (!this._config.ias_enabled) return Promise.resolve([]);

    const ad_slots = compact(ad_units.map(({name, instance}) => {
      const unit = this._config.ad_placements[name];
      if (!unit.sizes) return;

      const id = this._id_generator.id_for(name, instance);

      return {
        adSlotId: id,
        size: unit.sizes[0],
        adUnitPath: `/${this._config.dfp_network_id}/${name}`,
        timeout: this._config.prebid_timeout_ms,
      };
    }));

    if (isEmpty(ad_slots)) return Promise.resolve([]);

    const promised_targeting = new Promise((resolve, reject) => {
      count('ias.optimize.request', {source: 'ads'});
      window.__iasPET.queue.push({
        adSlots: ad_slots,
        dataHandler(response) {
          try {
            const {code, fr, brandSafety, slots: iasSlots} = JSON.parse(response);

            if (code && code !== 200) throw new Error(`Bad response code ${code}`);

            count('ias.optimize.response', {source: 'ads'});

            const shared_targeting = Object.assign({fr, ias_error: 'false'}, brandSafety);

            resolve(
              ad_slots.map(({adSlotId: id}) => {
                const targeting = Object.assign(
                  mapValues(pick(iasSlots[id], ['vw', 'grm']), values => values.join(',')),
                  shared_targeting
                );

                return {id, targeting};
              })
            );
          } catch (e) {
            reject(e);
          }
        },
      });

      setTimeout(
        () => reject(new Error('Timeout')),
        this._config.prebid_terminal_timeout_ms
      );
    });

    return promised_targeting.catch(() => {
      count('ias.optimize.error', {source: 'ads'});
      return ad_slots.map(
        ({adSlotId: id}) => ({id, targeting: {ias_error: 'true'}})
      );
    });
  }

  trigger_user_syncs() {}
  remove_ad_units() {}
}
