/* eslint-env es6 */

export default class IdGenerator {
  constructor(initial_last_reset_at) {
    this.last_reset_at = initial_last_reset_at;
  }

  reset_for_new_page_view() {
    this.last_reset_at = Date.now();
  }

  id_for(name, instance) {
    return `div-gpt-ad-${name}-${instance}-${this.last_reset_at}`;
  }
}
