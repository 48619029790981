/* eslint-env es6 */

export default class PromiseStore {
  constructor() {
    this.promises = new Map();
    this.resolvers = new Map();
  }

  clear() {
    this.promises.clear();
    this.resolvers.clear();
  }

  has(key) {
    return this.promises.has(key) && !this.resolvers.has(key);
  }

  set(key, value) {
    this._ensure_promise(key);
    this.resolvers.get(key)(value);
    this.resolvers.delete(key);
    return this.promises.get(key);
  }

  conditionally_set(key, fn) {
    return this.has(key) ? this.get(key) : this.set(key, fn());
  }

  get(key)  {
    this._ensure_promise(key);
    return this.promises.get(key);
  }

  multi_get(keys) {
    return Promise.all(keys.map(this.get.bind(this)));
  }

  delete(key) {
    this.promises.delete(key);
    this.resolvers.delete(key);
  }

  keys() {
    return Array.from(this.promises.keys());
  }

  _ensure_promise(key) {
    if (this.promises.has(key)) return;
    this.promises.set(key,
      new Promise(resolve => this.resolvers.set(key, resolve)));
  }
}
